import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby';

import SEO from "../../components/seo";
import ProjectLayout from "../../components/layouts/projectLayout";
import Banner from "../../components/project/banner";
import ProjectSection from "../../components/project/projectSection";
import ProjectFooter from "../../components/project/projectFooter";
import ScreenshotsContainer from "../../components/project/screenshotsContainer";
import {ProjectSectionTitle, ProjectSectionsubtitle, ProjectSectionDescription, ProjectSectionList, ProjectSectionListItem} from "../../components/project/projectTypography";
import {NewDesignSystem, IntegrationCloudService, IntegrationFlowDesigner, ApiPlatform} from "../../components/img/oracleCloudImages";

import Colors from "../../constants/colors";
import Fonts from "../../constants/fonts";
import Breakpoints from "../../constants/breakpoints";

// Images
import BannerImage from "../../images/project_banners/Project_banner-Oracle_Cloud.png";
import GoalsNewDesignImage from "../../images/projects/Oracle_Cloud/goals/brand_new_design.png";
import GoalsIntegrationImage from "../../images/projects/Oracle_Cloud/goals/integration_cloud_service.png";
import GoalsApiImage from "../../images/projects/Oracle_Cloud/goals/api_platform.png";

const getProject = graphql`
  query {
    project:allContentfulProject(filter: {title:{eq: "Oracle Cloud"}}) {
      edges {
        node {
          title
          description {
            description
          }
          slug
          order
          year
          role
          contentful_id
        }
      }
    }
  }
`;

const GoalList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, minmax(10rem, 27rem));
  grid-gap: 2.5rem;
  justify-content: center;

  list-style: none;
  margin: 0;


  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    grid-gap: 1.5rem;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
  
  li {
    display: block;
    background-color: ${Colors.Basic.white};
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    padding: 16px 20px 45.5% 20px;
    position: relative;

    @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
      padding-bottom: 40%;
    }

    @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
      padding-bottom: 48%;
    }

    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
      padding-bottom: 44%;
    }

    h4 {
      font-family: ${Fonts.Font_family.sans_serif_condensed};
      font-size: 1.125rem;
      line-height: 24px;
      color: ${Colors.Text.emphasized};
      margin-bottom: 44px;
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 12px;
        height: 1px;
        position: absolute;
        bottom: -6px;
      }
    }

    &.goals-image {
      background-position: center bottom;
      background-size: 100.5% auto;
      background-repeat: no-repeat;
    }

    &.goals-image-new_design {
      background-image: url(${GoalsNewDesignImage});

      h4 {
        &::after {
          background-color: ${Colors.Underline.blue};
        }
      }
    }

    &.goals-image-Integration {
      background-image: url(${GoalsIntegrationImage});

      h4 {
        &::after {
          background-color: ${Colors.Underline.pink};
        }
      }
    }

    &.goals-image-Api {
      background-image: url(${GoalsApiImage});

      h4 {
        &::after {
          background-color: ${Colors.Underline.green};
        }
      }
    }
  }
`;

const OracleCloud = () => {
  const data = useStaticQuery(getProject);
  const project = data.project.edges[0].node;
  const sections = [
    {
      "name": "Goals",
      "to": "goals"
    },
    {
      "name": "New Design System",
      "to": "new-design-system"
    },
    {
      "name": "Integration Cloud Service",
      "to": "integration-cloud-service"
    },
    {
      "name": "API Platform",
      "to": "api-platform"
    }
  ]

  return (  
    <ProjectLayout projectTitle={project.title} sections={sections}>
      <SEO title="Project - Oracle Cloud Platform" />
      <Banner
        bgColor="#FAF2F5"
        bgImage={BannerImage}
        bgSize="auto 90%"
        bgPosY="0"
        title={project.title}
        description={project.description.description}
        year={project.year}
        role={project.role}
      />

      <ProjectSection bgColor={Colors.Basic.gray_98} description="false" name={sections[0].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[0].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Move forward to cloud
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description section-content__description--list">
            <ProjectSectionDescription>
              Oracle Cloud Infrastructure supports traditional workloads and delivers modern cloud development tools. 
            </ProjectSectionDescription>
            <ProjectSectionList>
              <ProjectSectionListItem>Brand new UI/UX design</ProjectSectionListItem>
              <ProjectSectionListItem>Integration Cloud Service</ProjectSectionListItem>
              <ProjectSectionListItem>API Platform</ProjectSectionListItem>
            </ProjectSectionList>
          </div>
        </div>
        <GoalList>
          <li className="goals-image goals-image-new_design" data-sal="slide-up" data-sal-delay="300" data-sal-duration="1200">
            <h4>Brand New UI/UX design</h4>
          </li>
          <li className=" goals-image goals-image-Integration" data-sal="slide-up" data-sal-delay="450" data-sal-duration="1200">
            <h4>Integration Cloud Service</h4>
          </li>
          <li className=" goals-image goals-image-Api" data-sal="slide-up" data-sal-delay="600" data-sal-duration="1200">
            <h4>API Platform</h4>
          </li>
        </GoalList>
      </ProjectSection>

      <ProjectSection  bgColor="#2E3033" name={sections[1].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle color={Colors.Basic.white}>{sections[1].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle color={Colors.Basic.white}>
              Build new modern design system from scratch
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description section-content__description--list">
            <ProjectSectionDescription color={Colors.Basic.white}>
              The original design is a little outdated and lack of consistent documentation. We build a new design system and guideline website. 
            </ProjectSectionDescription>
            <ProjectSectionList>
              <ProjectSectionListItem color={Colors.Basic.white}>Standard colors and typography</ProjectSectionListItem>
              <ProjectSectionListItem color={Colors.Basic.white}>Consistent compoments guidelin</ProjectSectionListItem>
              <ProjectSectionListItem color={Colors.Basic.white}>Icon library</ProjectSectionListItem>
            </ProjectSectionList>
          </div>
        </div>
        <NewDesignSystem></NewDesignSystem>
      </ProjectSection>

      <ProjectSection bgColor="#FAFAFA" noDescription name={sections[2].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[2].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Intuitive user experience with no coding
            </ProjectSectionsubtitle>
          </div>
        </div>
        <ScreenshotsContainer column infoWidth="100%">
          <div className="info">
            <h4>portal / navigation / List</h4>
            <p>Organized and clear information to guide users to easily find where they need.</p>
          </div>
          <IntegrationCloudService></IntegrationCloudService>
        </ScreenshotsContainer>
        <ScreenshotsContainer column infoWidth="100%">
          <div className="info">
            <h4>Integration Flow Designer</h4>
            <p>Simplify the process of making integration by visualizing the interactive components.</p>
          </div>
          <IntegrationFlowDesigner></IntegrationFlowDesigner>
        </ScreenshotsContainer>
      </ProjectSection>
      
      <ProjectSection bgColor="#3B4045" noDescription name={sections[3].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle color={Colors.Basic.white}>{sections[3].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle color={Colors.Basic.white}>
              Streamline interface of implementing and monitoring APIs
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description">
            <ProjectSectionDescription color={Colors.Basic.white}>
              The concept is to craft clear flows for API creation process and rich visualized tracking tools. We separated the tasks and settings into several categories and sorted them by workflow sequence.
            </ProjectSectionDescription>
          </div>
        </div>
        <ApiPlatform></ApiPlatform>
      </ProjectSection>

      <ProjectFooter currentProjectOrder="4">
      </ProjectFooter>
    </ProjectLayout>
  )
};

export default OracleCloud;
