import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components';

import Colors from '../../constants/colors';
import Fonts from '../../constants/fonts';
import Breakpoints from "../../constants/breakpoints";

const getData = graphql`
  query {
    newDesignSystem: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Oracle_Cloud/new_design_system" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    integrationCloudService: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Oracle_Cloud/integration_cloud_service" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    integrationFlowDesigner: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Oracle_Cloud/integration_flow_designer" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    apiPlatform: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Oracle_Cloud/API_platform" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

function withImageData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={getData}
      render={data => <WrappedComponent {...props} imageData={data} />}
    />
  );
}

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    flex-direction: column;
  }

  .container {
    display: flex;
    flex-direction: column;

    h4 {
      font-family: ${Fonts.Font_family.title_condensed};
      font-size: 1.375rem;
      line-height: 21px;
      color: ${props => props.lightText ? Colors.Basic.white : Colors.Text.default};
      margin-bottom: 32px;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 12px;
        height: 1px;
        background-color: ${Colors.Underline.blue};
        position: absolute;
        bottom: -8px;
        left: 0;
      }
    }
  }

`;

const NewDesignSystemImages = styled(ImageContainer)`
  > div {
    &:nth-child(1) {
      width: 36.5854%;
      max-width: 480px;

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:nth-child(2) {
      width: 27.4391%;
      max-width: 360px;

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:nth-child(3) {
      width: 21.3415%;
      max-width: 280px;
      
      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:not(:last-child) {
      margin-right: 6.1737%;

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

const IntegrationCloudServiceImages = styled(ImageContainer)`
   > div {
    width: 31%;
    max-width: 400px;

    @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
      width: 100%;
      max-width: 100%;
    }

    &:not(:last-child) {
      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

const IntegrationFlowDesignerImages = styled(ImageContainer)`
  flex-wrap: wrap;
  justify-content: space-between;

   > div {
    width: 31%;
    max-width: 400px;
    margin-bottom: 3%;

    @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
      width: 100%;
      max-width: 100%;
    }

    &:not(:last-child) {
      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

const ApiPlatformImages = styled.div`
  display: grid;
  grid-template-columns: 12fr 19fr;
  grid-gap: 40px;

  @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
    grid-gap: 32px;
  }

  @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
    grid-gap: 24px;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    grid-gap: 16px;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }

  > div {
    &:nth-child(1) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
    &:nth-child(2) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    &:nth-child(3) {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }
    &:nth-child(4) {
      grid-column: 2 / 3;
      grid-row: 1 / 4;

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        grid-column: 1 / 2;
        grid-row: 4 / 5;
      }
    }
  }
`;

export const NewDesignSystem = withImageData(props => (
  <NewDesignSystemImages lightText data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.newDesignSystem.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </NewDesignSystemImages>
));

export const IntegrationCloudService = withImageData(props => (
  <IntegrationCloudServiceImages lightText data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.integrationCloudService.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </IntegrationCloudServiceImages>
));

export const IntegrationFlowDesigner = withImageData(props => (
  <IntegrationFlowDesignerImages lightText data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.integrationFlowDesigner.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </IntegrationFlowDesignerImages>
));

export const ApiPlatform = withImageData(props => (
  <ApiPlatformImages lightText data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.apiPlatform.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </ApiPlatformImages>
));
